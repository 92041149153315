<template>
  <div class="wrap">
<!--    <img :src="presonal" alt="#">-->
    <div class="content">
      <div class="title">Contact</div>
      <div class="line"></div>
      <div class="mail">E-mail</div>
      <div class="mail-text">1490296240@qq.com</div>
      <div class="phone">phone</div>
      <div class="phone-text">(86) 13358778768</div>
    </div>
    <div class="bottom">辽ICP备2022001219号</div>
  </div>
</template>

<script>
import presonal1 from '/public/index/personal.jpg'
export default {
  data(){
    return {
      presonal: presonal1,
    }
  }
}
</script>

<style lang="scss" scoped>

@font-face {
  font-family: 'DIN';  //重命名字体名
  //src: url('/public/DIN-Bold.otf');  //引入字体
  font-weight: normal;
  font-style: normal;
}
.wrap {
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
  .content {
    padding: 60px;
    background: #ffd501;
    font-family: DIN;
    .title{
      font-weight: 500;
      font-size: 44px;
    }
    .line{
      width: 60px;
      height: 1px;
      background-color: black;
      margin: 0 auto;
    }
    .mail{
      font-weight: 500;
      margin-top: 20px;
      font-size: 22px;
    }
    .mail-text{
      font-family: DIN;
      font-weight: 500;
      margin-top: 10px;
      font-size: 22px;
    }
    .phone{
      font-weight: 500;
      margin-top: 10px;
      font-size: 22px;
    }
    .phone-text{
      font-weight: 500;
      margin-top: 10px;
      font-size: 22px;
    }
  }
  .bottom {
    color: white;
    padding: 6px;
    text-align: center;
    background: black;
  }
}
</style>
