import { createRouter, createWebHashHistory } from 'vue-router'

// 仅做示例，根据业务修改路径等
import Index from '../components/Index.vue'
import Children from '../components/ChildPage/index.vue'

const routes = [
    {
        path: '/',
        redirect: '/index',
        name: 'index',
        meta: {
            title: 'index'
        }
    },
    {
        path: '/index',
        component: Index,
        name: 'hello',
        meta: {
            title: 'index1'
        }
    },
    {
        path: '/children/:id',
        component: Children,
        name: 'children',
        meta: {
            title: 'index'
        }
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// 前置路由
// router.beforeEach((to, from, next) => {
// })

export default router
