<template>
  <div id='wrap'>
    <div class="title">
      <h1>Works</h1>
      <div class="unit"></div>
    </div>
    <div class="list">
      <div class="list-item" v-for='(item,key) in list' :key='key' @click='router(item)'>
        <div class="list-item-opction">
          <div class="dec">
            <span class="text">{{item.text}}</span>
            <div class="line"></div>
            <span class='dec'>{{item.dec}}</span>
          </div>
        </div>
        <img class="list-item-img" style="width:100%;display:block" :src='item.bg' alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import list1 from '/public/index/list/1-国大药房品牌形象升级解决方案头图.jpg'
import list2 from '/public/index/list/2-国大药房品牌vi设计方案头图.jpg'
import list3 from '/public/index/list/3-青蛙王子包装升级-头图.jpg'
import list4 from '/public/index/list/4-北京外国语学院附属新华学校品牌塑造头图.jpg'
import list5 from '/public/index/list/5-北京外国语学院附属新华学校导视系统塑造头图.jpg'
import list6 from '/public/index/list/6-北京外国语学院附属新华学习文化长廊头图.jpg'
import list7 from '/public/index/list/7-光大证券品牌升级方案(VIS)头图.jpg'
import list8 from '/public/index/list/8-牛人专线品牌形象升级视觉方案头图.jpg'
import list9 from '/public/index/list/9-牛人专线LOGO视觉提案头图.jpg'
import list10 from '/public/index/list/10-通联支付“通商云”品牌升级方案-03.jpg'
import list11 from '/public/index/list/11-新华发展集团品牌升级解决方案(VIS)头图.jpg'
import list12 from '/public/index/list/12-鼎桥头图.jpg'
import list13 from '/public/index/list/13-瑞福德品牌VI升级方案头图.jpg'
import list14 from '/public/index/list/14-瑞福德汽车金融新年文创方案头图.jpg'
import list15 from '/public/index/list/15-中海信托辅助形象方案头图.jpg'
import list16 from '/public/index/list/16-崇明璞叶小镇导视系统建设头图.jpg'
import list17 from '/public/index/list/17-崇明璞叶头图.jpg'
import list18 from '/public/index/list/18-上海航天卫星应用有限公司头图.jpg'
import list19 from '/public/index/list/19-上海科技创新资源数据中心品牌形象升级头图.jpg'
import list20 from '/public/index/list/20-上海循曜生物品牌建设方案头图.jpg'
import list21 from '/public/index/list/21-屹唐头图.jpg'
import list22 from '/public/index/list/22-天元锰业集团品牌形象升级头图.jpg'
import list23 from '/public/index/list/23-南水北调东线形象设计方案头图.jpg'
import {useRouter} from 'vue-router'
const route = useRouter();
let router = (item) => {
  console.log(item);
  route.push({ name: 'children',  params: {id:item.id} })
}
let list = [
  {bg: list1,text: '国大药房品牌形象升级解决方案',dec: '形象升级',id:0},
  {bg: list2,text: '国大药房品牌vi设计方案',dec: 'vi设计',id:1},
  {bg: list3,text: '青蛙王子包装升级',dec: '包装升级',id:2},
  {bg: list4,text: '北京外国语学院附属新华学校品牌塑造',dec: '品牌塑造',id:3},
  {bg: list5,text: '北京外国语学院附属新华学校导视系统塑造',dec: '导视系统塑造',id:4},
  {bg: list6,text: '北京外国语学院附属新华学习文化长廊',dec: '文化长廊',id:5},
  {bg: list7,text: '光大证券品牌升级方案(VIS)',dec: 'VIS',id:6},
  {bg: list8,text: '牛人专线品牌形象升级视觉方案',dec: '升级视觉',id:7},
  {bg: list9,text: '牛人专线LOGO视觉提案',dec: 'LOGO',id:8},
  {bg: list10,text: '通联支付“通商云”品牌升级',dec: '品牌升级',id:9},
  {bg: list11,text: '新华发展集团品牌升级解决方案(VIS)',dec: 'VIS',id:10},
  {bg: list12,text: '鼎桥科技品牌升级方案',dec: '品牌升级',id:11},
  {bg: list13,text: '瑞福德品牌VI升级方案',dec: 'VI升级',id:12},
  {bg: list14,text: '瑞福德汽车金融新年文创方案',dec: '文创',id:13},
  {bg: list15,text: '中海信托辅助形象方案',dec: '辅助图形',id:14},
  {bg: list16,text: '崇明璞叶小镇导视系统建设',dec: '导视系统',id:15},
  {bg: list17,text: '崇明璞叶小镇品牌形象建设',dec: '品牌形象',id:16},
  {bg: list18,text: '上海航天卫星应用有限公司品牌形象建设',dec: '品牌形象建设',id:17},
  {bg: list19,text: '上海科技创新资源数据中心品牌形象升级',dec: '品牌形象升级',id:18},
  {bg: list20,text: '上海循曜生物品牌建设方案',dec: '品牌建设',id:19},
  {bg: list21,text: '屹唐半导体品牌升级方案',dec: '品牌升级',id:20},
  {bg: list22,text: '天元锰业集团品牌形象升级',dec: '品牌形象',id:21},
  {bg: list23,text: '南水北调东线形象设计方案',dec: '形象设计',id:22},
]
</script>

<style scoped lang="scss">
#wrap {
  margin-top: 30px;
  margin-bottom: 120px;
  width: 100%
}
.title {
 text-align: center;
 margin-bottom: 30px;
 font-size: 26px;
}
h1{
  margin-bottom: 0;
}
.unit {
  width: 20px;
  height: 1px;
  background-color: #000000;
  margin: 0 auto;
}
.list {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 0 auto;
  cursor: pointer;;
  .list-item {
    position: relative;
    width: 33.33%;
    .list-item-opction {
      color: #fff;
      width: 100%;
      height: 100%;
      opacity: 0;
      left: 0;
      top: 0;
      background: rgba(0,0,0,0.8);
      position: absolute;
      z-index:999;
      transition: .4s;
      .text {
        font-size: 16px;
      }
      .line {
        width: 20px;
        height: 1px;
        background-color: #fff;
        margin: 10px auto;
      }
      .dec {
        margin-top: 120px;
      }
    }
    .list-item-img {
      position: relative;
    }
    &:hover{
      .list-item-opction {
        opacity: 1;
      }
    }
  }
}

</style>
