<template>
  <div id='wrap'>
    <p @click="index">首页</p>
    <div class="title">
      <img ref="titleimg" :src="titleImg" alt="#">
    </div>
    <div class="body">
      <img ref="bodyimg" :src="bodyImg" alt="#">
    </div>
  </div>
</template>

<script>
import children11 from '../../assets/children/children1/children1.jpg'
import children12 from '../../assets/children/children1/children2.jpg'
import children21 from '../../assets/children/children2/children1.jpg'
import children22 from '../../assets/children/children2/children2.jpg'
import children31 from '../../assets/children/children3/children1.jpg'
import children32 from '../../assets/children/children3/children2.jpg'
import children41 from '../../assets/children/children4/children1.jpg'
import children42 from '../../assets/children/children4/children2.jpg'
import children51 from '../../assets/children/children5/children1.jpg'
import children52 from '../../assets/children/children5/children2.jpg'
import children61 from '../../assets/children/children6/children1.jpg'
import children62 from '../../assets/children/children6/children2.jpg'
import children71 from '../../assets/children/children7/children1.jpg'
import children72 from '../../assets/children/children7/children2.jpg'
import children81 from '../../assets/children/children8/children1.jpg'
import children82 from '../../assets/children/children8/children2.jpg'
import children91 from '../../assets/children/children9/children1.jpg'
import children92 from '../../assets/children/children9/children2.jpg'
import children101 from '../../assets/children/children10/children1.jpg'
import children102 from '../../assets/children/children10/children2.jpg'
import children111 from '../../assets/children/children11/children1.jpg'
import children112 from '../../assets/children/children11/children2.jpg'
import children121 from '../../assets/children/children12/children1.jpg'
import children122 from '../../assets/children/children12/children2.jpg'
import children131 from '../../assets/children/children13/children1.jpg'
import children132 from '../../assets/children/children13/children2.jpg'
import children141 from '../../assets/children/children14/children1.jpg'
import children142 from '../../assets/children/children14/children2.jpg'
import children151 from '../../assets/children/children15/children1.jpg'
import children152 from '../../assets/children/children15/children2.jpg'
import children161 from '../../assets/children/children16/children1.jpg'
import children162 from '../../assets/children/children16/children2.jpg'
import children171 from '../../assets/children/children17/children1.jpg'
import children172 from '../../assets/children/children17/children2.jpg'
import children181 from '../../assets/children/children18/children1.jpg'
import children182 from '../../assets/children/children18/children2.jpg'
import children191 from '../../assets/children/children19/children1.jpg'
import children192 from '../../assets/children/children19/children2.jpg'
import children201 from '../../assets/children/children20/children1.jpg'
import children202 from '../../assets/children/children20/children2.jpg'
import children211 from '../../assets/children/children21/children1.jpg'
import children212 from '../../assets/children/children21/children2.jpg'
import children221 from '../../assets/children/children22/children1.jpg'
import children222 from '../../assets/children/children22/children2.jpg'
import children01 from '../../assets/children/children0/children1.jpg'
import children02 from '../../assets/children/children0/children2.jpg'
export default {
  data() {
    return {
      id: '',
      children: [
        {
          title: children01,
          body: children02,
        },
        {
          title: children11,
          body: children12,
        },
        {
          title: children21,
          body: children22,
        },
        {
          title: children31,
          body: children32,
        },
        {
          title: children41,
          body: children42,
        },
        {
          title: children51,
          body: children52,
        },
        {
          title: children61,
          body: children62,
        },
        {
          title: children71,
          body: children72,
        },
        {
          title: children81,
          body: children82,
        },
        {
          title: children91,
          body: children92,
        },
        {
          title: children101,
          body: children102,
        },
        {
          title: children111,
          body: children112,
        },
        {
          title: children121,
          body: children122,
        },
        {
          title: children131,
          body: children132,
        },
        {
          title: children141,
          body: children142,
        },
        {
          title: children151,
          body: children152,
        },
        {
          title: children161,
          body: children162,
        },
        {
          title: children171,
          body: children172,
        },
        {
          title: children181,
          body: children182,
        },
        {
          title: children191,
          body: children192,
        },
        {
          title: children201,
          body: children202,
        },
        {
          title: children211,
          body: children212,
        },
        {
          title: children221,
          body: children222,
        },
      ],
    }
  },
  computed: {
    titleImg() {
      return this.children[this.id].title
    },
    bodyImg() {
      return this.children[this.id].body
    },
  },
  created() {
    this.id = this.$route.params.id
    // this.$nextTick(()=>{
    //   this.$refs.titleimg.src = `../../assets/children/children${this.id}/children1.jpg`
    //   this.$refs.bodyimg.src = `../../assets/children/children${this.id}/children2.jpg`
    // })
  },
  methods: {
    index () {
      this.$router.push({path: '/index'})
    },
  },

}
</script>

<style scoped lang="scss">
p {}
#wrap {
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 20px;
  width: 90%
}
.title {
 text-align: center;
 margin-bottom: 30px;
  img{
    display: block;
    width: 100%;
  }
}
.body {
  width: 100%;
  img{
    display: block;
    width: 100%;
  }
}

</style>
