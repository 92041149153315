<template>
  <div class="wrap">
    <div class="title">
      <h1>About</h1>
      <div class="unit"></div>
    </div>
    <div class="icon">
      <div class="icon-list" v-for='(item,key) in icon' :key="key">
        <div class="icon-list-item">
          <img :src="item.icon" alt="#">
        </div>
        <div class="icon-list-text">{{item.text}}</div>
        <div class="icon-list-dec">{{item.dec}}</div>
      </div>
    </div>
    <div class="explain">
      <div class="explain-item" v-for='(item,key) in dec' :key='key'>
        <div class="title-item">
          {{item.title}}
        </div>
        <div class="job">
          {{item.job}}
        </div>
        <div class="time">
          {{item.time}}
        </div>
        <div class="execute">
          {{item.execute}}
        </div>
        <div class="execute1">
          {{item.execute1}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import icon1 from '/public/index/about/one.png'
import icon2 from '/public/index/about/two.png'
import icon3 from '/public/index/about/three.png'
//import explain from '/public/index/about/line.png'
export default {
  name: "index",
  data() {
    return {
      //explain: explain,
      icon:[
        {
          icon: icon1,
          text: '品牌全案+平面广告',
          dec: '7年设计工作经验',
        },
        {
          icon: icon2,
          text: '辽宁工业美术学院 视觉传达',
          dec: '东华大学 视觉传达（第二学历）',
        },
        {
          icon: icon3,
          text: '设计是我的爱好',
          dec: '也是我的职业',
        },
      ],
      dec: [
        {
          title: '沈阳裕和包装设计',
          job: '设计师',
          time: '2015.11-2016.12',
          execute: '创意执行•插画执行•品牌传播',
          execute1: '',
        },
        {
          title: '沈阳熙云谷品牌创意',
          job: '',
          time: '2016.12-2017.9',
          execute: '创意执行•插画执行•策略方案',
          execute1: '品牌传播',
        },
        {
          title: '沈阳𡰪沃品牌创意',
          job: '美术指导',
          time: '2017.10-2019.02',
          execute: '前期创意•设计执行•带领团队',
          execute1: '插画创意•策略方案',
        },
        {
          title: '上海上喆品牌创意',
          job: '资深美术指导',
          time: '2019.03-2020.04',
          execute: '前期创意•设计执行•带领团队',
          execute1: '插画创意•策略方案',
        },
        {
          title: '上海和与德文化',
          job: '美术指导',
          time: '2020.4-2022',
          execute: '方案拟定•创意执行•对接提案',
          execute1: '带领团队•工作整合',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
 .wrap {
   padding: 30px 0;
   background-color: #ffd501;
   width: 100%;
   .title {
    text-align: center;
    margin-bottom: 30px;
   }
   h1{
     font-size: 60px;
     margin-bottom: 0;
   }
   .unit {
     width: 20px;
     height: 1px;
     background-color: #000000;
     margin: 0 auto;
   }
   .icon{
     margin: 0 auto;
     width: 60%;
     display: flex;;
   }
   .icon-list {
     width: 33.33%;
     font-weight: 300;
   }
   .icon-list-item {
     width: 50%;
     margin: 0 auto;
   }
   .icon-list-text {
     margin-top: 30px;
   }
   img {
     display: block;
     width: 100%
   }
   .explain {
     display: flex;
     margin: 60px auto;
     width: 1200px;
     min-width: 800px;
     height: 100px;
     background-image: url("../../assets/line.png");
     background-size: 100% 75%;
     background-repeat: no-repeat;
     text-align: left;
     padding: 0 20px;
     .explain-item {
       font-family:sans-serif;
       width: 18%;
       margin-right: 40px;
       font-weight: 200;
       .title-item{
         margin: 10px 0 10px 0;
         text-align: left;
         font-size: 16px;
       }
       .job{
         min-height: 20px;
       }
       .time{
       }
       .execute {

       }
       .execute1 {}
     }
     .explain-item:nth-child(2) {
       margin-left: 40px;
     }
     .explain-item:nth-child(3) {
       margin-left: 40px;
     }
     .explain-item:nth-child(4) {
       margin-left: 40px;
     }
     .explain-item:nth-child(5) {
       margin-left: 30px;
     }
   }
 }
</style>
